
$(document).ready(function () {
    //Objects page hash handling

    if($(".objects_slider").length)
    {
        var hash = window.location.hash;
        if (location.hash) {
          setTimeout(function() {
            window.scrollTo(0, 0);
            history.replaceState("", document.title, window.location.pathname);
          }, 1);
        }
        if (hash !== "")
        {
            $(".objects_slider>article").removeClass("active");
            handleObjectAnchor($(".objects_slider>article"+ hash +""));
            setTimeout(function () {
                $('html, body').animate({ scrollTop: $(hash).offset().top-200 }, 400);
            }, 1000);
        }
        $("body>header .nav a").on("click", function () {
            var hash = "#"+$(this).attr("href").split('#')[1];
            var page = "objects.html";
            if ($(this).attr("href").indexOf(page) > -1) {
                var hash1 = $(this).attr("href").split('#')[1];
                if (hash !== "")
                {
                    $(".objects_slider>article").removeClass("active");
                    history.replaceState("", document.title, window.location.pathname);
                    handleObjectAnchor($(".objects_slider>article#"+ hash1 +""));
                    setTimeout(function () {
                        $('html, body').animate({ scrollTop: $(hash).offset().top-50 }, 400);
                    }, 1000);
                    return false;
                }
            }
        });
        $("body>footer a").on("click", function () {
            var hash = "#"+$(this).attr("href").split('#')[1];
            var page = "objects.html";
            if ($(this).attr("href").indexOf(page) > -1) {
                var hash1 = $(this).attr("href").split('#')[1];
                if (hash !== "")
                {
                    $(".objects_slider>article").removeClass("active");
                    history.replaceState("", document.title, window.location.pathname);
                    handleObjectAnchor($(".objects_slider>article#"+ hash1 +""));
                    setTimeout(function () {
                        $('html, body').animate({ scrollTop: $(hash).offset().top-50 }, 400);
                    }, 1000);
                    return false;
                }
            }
        });
    }

    //Services page hash handling

    if($(".block_services").length)
    {
        var hash = window.location.hash;
        if (location.hash) {
          setTimeout(function() {
            window.scrollTo(0, 0);
            history.replaceState("", document.title, window.location.pathname);
          }, 1);
        }
       if (hash !== "")
        {
            $(".block_services>div").removeClass("active");
            $(".block_services>div"+ hash +"").addClass("active");
            setTimeout(function () {
                $('html, body').animate({ scrollTop: $(hash).offset().top-170 }, 400);
            }, 500);
        }
        $("body>header .nav a").on("click", function () {
            var hash = "#"+$(this).attr("href").split('#')[1];
            var page = "services.html";
            if ($(this).attr("href").indexOf(page) > -1) {
                var hash1 = $(this).attr("href").split('#')[1];
                if (hash !== "")
                {
                    $(".block_services>div").removeClass("active");
                    history.replaceState("", document.title, window.location.pathname);
                    setTimeout(function () {
                        $('html, body').animate({ scrollTop: $(hash).offset().top-30 }, 400);
                        $(".block_services>div#"+ hash1 +"").addClass("active");
                    }, 100);
                    return false;
                }
            }
        });
        $("body>footer a").on("click", function () {
            var hash = "#"+$(this).attr("href").split('#')[1];
            var page = "services.html";
            if ($(this).attr("href").indexOf(page) > -1) {
                var hash1 = $(this).attr("href").split('#')[1];
                if (hash !== "")
                {
                    $(".block_services>div").removeClass("active");
                    history.replaceState("", document.title, window.location.pathname);
                    setTimeout(function () {
                        $('html, body').animate({ scrollTop: $(hash).offset().top-30 }, 400);
                        $(".block_services>div#"+ hash1 +"").addClass("active");
                    }, 100);
                    return false;
                }
            }
        });
    }

    //About page hash handling
    if($("#about").length)
    {
        var hash = window.location.hash;
        if (location.hash) {
          setTimeout(function() {
            window.scrollTo(0, 0);
            history.replaceState("", document.title, window.location.pathname);
          }, 1);
        }
        if (hash !== "")
        {
            setTimeout(function () {
                $('html, body').animate({ scrollTop: $(hash).offset().top-170 }, 400);
            }, 100);
        }
        $("body>header .nav a").on("click", function () {
            var hash = $(this.hash);
            var page = "about.html";
            if ($(this).attr("href").indexOf(page) > -1) {
                if (hash !== "")
                {
                    history.replaceState("", document.title, window.location.pathname);
                    setTimeout(function () {
                        $('html, body').animate({ scrollTop: $(hash).offset().top-30 }, 400);
                    }, 100);
                    return false;
                }
            }
        });
        $("body>footer a").on("click", function () {
            var hash = $(this.hash);
            var page = "about.html";
            if ($(this).attr("href").indexOf(page) > -1) {
                if (hash !== "")
                {
                    history.replaceState("", document.title, window.location.pathname);
                    setTimeout(function () {
                        $('html, body').animate({ scrollTop: $(hash).offset().top-30 }, 400);
                        
                    }, 100);
                    return false;
                }
            }
        });
    }
})
window.onload = function(){
    if (window.location.hash == "") {
        console.log("there is no hash in url");
        setTimeout(function () {
            $(window).scrollTop($(window).scrollTop()+11);
        }, 100);
        
    }
    $(".objects_slider .pic .next").on("click", function (e) {
        e.preventDefault();
        $('.objects_slider .active .body .wrapper').slick('unslick');
        $(".objects_slider>article").removeClass("active");
        var that = $(this).closest("article").next("article");
        setTimeout(function () {
            that.addClass('active');
            that.find('.body .wrapper').slick({
                dots: false,
                infinite: true,
                speed: 1000,
                cssEase: 'ease-in-out',
                slidesToShow: 3,
                prevArrow: "<div class='slick_prev'></div>",
                nextArrow: "<div class='slick_next'></div>"
            });
        }, 500);

    });

    $(".objects_slider .pic .prev").on("click", function (e) {
        e.preventDefault();
        $('.objects_slider .active .body .wrapper').slick('unslick');
        $(".objects_slider>article").removeClass("active");
        var that = $(this).closest("article").prev("article");
        setTimeout(function () {
            that.addClass('active');
            that.find('.body .wrapper').slick({
                dots: false,
                infinite: true,
                speed: 1000,
                cssEase: 'ease-in-out',
                slidesToShow: 3,
                prevArrow: "<div class='slick_prev'></div>",
                nextArrow: "<div class='slick_next'></div>"
            });
        }, 500);
    });
    
    
    var hash = window.location.hash;
    if (hash !== "")
    {
        $('html, body').animate({ scrollTop: $(hash).offset().top-50 }, 400);
    }



    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 50) {
            $('body>header').addClass('fixed');
        } else {
            $('body>header').removeClass('fixed');
        }
    });

    $('.call_request_trigger').click(function(e){
    	e.preventDefault();
    	$('.w_popup').trigger('closeModal');
        $(".lean-overlay").remove();
		triggerModal('.w_popup.call_request');
	});

    $('.message_trigger').click(function(e){
    	e.preventDefault();
    	$('.w_popup').trigger('closeModal');
        $(".lean-overlay").remove();
		triggerModal('.w_popup.message');
	});

    $('.object_trigger').click(function(e){
    	e.preventDefault();
    	$('.w_popup').trigger('closeModal');
        $(".lean-overlay").remove();
		triggerModal('.w_popup.object');
	});

    $('.modal_close').on('click', function () {
        $(this).closest('.w_popup').trigger('closeModal');
    });

    $('.w_popup .ok').on('click', function () {
        $(this).closest('.w_popup').trigger('closeModal');
    });

    $('.service_block').on('click', function () {
        $(this).toggleClass('active');
    });

    $('.block_services .dropdown_close').on('click', function () {
            $(this).closest('.dropdown').prev('.service_block').removeClass('active');
    });

    $('.objects_slider .dropdown_close').on('click', function () {
            $(this).closest('article').removeClass('active');
    });

    if ($('.objects_slider').length) {
        $('.objects_slider .active .body .wrapper').slick({
            dots: false,
            infinite: true,
            speed: 1000,
            cssEase: 'ease-in-out',
            slidesToShow: 3,
            prevArrow: "<div class='slick_prev'></div>",
            nextArrow: "<div class='slick_next'></div>"
        });
        $('.objects_slider article header p').on("click", function () {
            $('.objects_slider .active .body .wrapper').slick('unslick');
            if (!$(this).closest('article').hasClass('active'))
            {
                $('.objects_slider article').removeClass('active');
                var that = $(this);
                setTimeout(function () {
                    that.closest('article').addClass('active');
                    that.closest('article').find('.body .wrapper').slick({
                        dots: false,
                        infinite: true,
                        speed: 1000,
                        cssEase: 'ease-in-out',
                        slidesToShow: 3,
                        prevArrow: "<div class='slick_prev'></div>",
                        nextArrow: "<div class='slick_next'></div>"
                    });
                }, 500);
            }
            $('.objects_slider article').removeClass('active');

        });
    }
    if ($('.objects_last').length) {
        $('.objects_last .slider').slick({
            dots: false,
            infinite: true,
            speed: 1000,
            cssEase: 'ease-in-out',
            slidesToShow: 3,
            prevArrow: "<div class='slick_prev'></div>",
            nextArrow: "<div class='slick_next'></div>"
        });
    }
    if ($('.index_slider').length) {
        $('.index_slider .back').jKit('parallax', { 'strength': '5', 'axis': 'y', 'detect': 'scroll' });
        $('.index_slider .back').mouseParallax({ moveFactor: 5 });
        $('.index_slider .text').mouseParallax({ moveFactor: 2 });
        $('.index_slider .slider').slick({
            dots: true,
            infinite: true,
            speed: 1000,
            cssEase: 'ease-in-out',
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            prevArrow: "<div class='slick_prev'></div>",
            nextArrow: "<div class='slick_next'></div>"
        });
    }
    if ($('.index_our_objects').length) {
        $('.index_our_objects .slider').slick({
            dots: false,
            infinite: true,
            speed: 1000,
            cssEase: 'ease-in-out',
            slidesToShow: 3,
            prevArrow: "<div class='slick_prev'></div>",
            nextArrow: "<div class='slick_next'></div>"
        });
    }
};

function triggerModal(name){
	$('body').css('overflow', 'hidden');
	$(name).easyModal({
		top: 96,
		autoOpen: true,
		overlayOpacity: 0.5,
		overlayColor: "#333",
		overlayClose: true,
		closeOnEscape: true,
		onClose: function(){
			$('body').css('overflow', 'auto');
		}
	});
}


function handleObjectAnchor (objects) {
    if (!objects.closest('article').hasClass('active'))
    {
        $('.objects_slider article').removeClass('active');
        setTimeout(function () {
            objects.closest('article').addClass('active');
            objects.closest('article').find('.body .wrapper').slick({
                dots: false,
                infinite: true,
                speed: 1000,
                cssEase: 'ease-in-out',
                slidesToShow: 3,
                prevArrow: "<div class='slick_prev'></div>",
                nextArrow: "<div class='slick_next'></div>"
            });
        }, 500);
    }
}